<template>
  <div>
    <header class="app-header fixed-top">
    <div class="app-header-inner">
      <div class="container-fluid py-2">
        <div class="app-header-content">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <a
                id="sidepanel-toggler"
                class="sidepanel-toggler d-inline-block d-xl-none"
                href="#"
                @click.prevent="_sidePanelToggler()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  role="img"
                >
                  <title>Menu</title>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    d="M4 7h22M4 15h22M4 23h22"
                  ></path>
                </svg>
              </a>
            </div>

            <div class="app-utilities col-auto">
              <div class="app-utility-item app-user-dropdown dropdown">
                <a
                  class="dropdown-toggle"
                  id="user-dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  ><img src="@/assets/images/user.png" alt="user profile"
                /></a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="user-dropdown-toggle"
                >
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#" @click.prevent="logout">Log Out</a>
                  </li>
                </ul>
              </div>
              <!--//app-user-dropdown-->
            </div>
            <!--//app-utilities-->
          </div>
          <!--//row-->
        </div>
        <!--//app-header-content-->
      </div>
      <!--//container-fluid-->
    </div>
    <!--//app-header-inner-->
    <div id="app-sidepanel" class="app-sidepanel">
      <div id="sidepanel-drop" class="sidepanel-drop"></div>
      <div class="sidepanel-inner d-flex flex-column">
        <a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none"
        @click.prevent="_sidePanelClose()"
          >&times;</a
        >
        <div class="app-branding">
          <router-link 
            to="/"
            class="app-logo"
          >
            <img
              class="logo-icon mr-2"
              src="@/assets/images/app-logo.svg"
              alt="logo"
            /><span class="logo-text">Doctor App</span>
          </router-link> 
        </div>
        <!--//app-branding-->

        <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
          <ul class="app-menu list-unstyled accordion" id="menu-accordion">
            <li class="nav-item">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <router-link 
                class="nav-link"
                to="/"
              >
                <span class="nav-icon">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-house-door"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                </span>
                <span class="nav-link-text">Dashboard</span> 
              </router-link>
            </li>
            <!--//nav-item-->
            <li class="nav-item">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <router-link to="/users"
                class="nav-link"
              >
                <span class="nav-icon">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-folder"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"
                      />
                    </svg>
                  </span>
                  <span class="nav-link-text">Users</span>
              </router-link>
            </li>
            <li class="nav-item">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <router-link to="/appointments"
                class="nav-link"
              >
                <span class="nav-icon">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-folder"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"
                      />
                    </svg>
                  </span>
                  <span class="nav-link-text">Appointment List</span>
              </router-link>
            </li>
          </ul>
          <!--//app-menu-->
        </nav>
        <!--//app-nav-->
      </div>
      <!--//sidepanel-inner-->
    </div>
    <!--//app-sidepanel-->
    </header>
    <div class="app-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
   data() {
    return {
      sidePanelToggler: null,
      sidePanel: null,
      sidePanelDrop: null,
      sidePanelClose: null,
    };
  },
  mounted() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
    this.sidePanelToggler = document.getElementById("sidepanel-toggler");
    this.sidePanel = document.getElementById("app-sidepanel");
    this.sidePanelDrop = document.getElementById("sidepanel-drop");
    this.sidePanelClose = document.getElementById("sidepanel-close");
    window.addEventListener("resize", this.responsiveSidePanel);
  },
  methods: {
    responsiveSidePanel() {
      let w = window.innerWidth;
      if (w >= 1200) {
        this.sidePanel.classList.remove("sidepanel-hidden");
        this.sidePanel.classList.add("sidepanel-visible");
      } else {
        // if smaller
        this.sidePanel.classList.remove("sidepanel-visible");
        this.sidePanel.classList.add("sidepanel-hidden");
      }
    },
    _sidePanelToggler() {
      if (this.sidePanel.classList.contains('sidepanel-visible')) {
        this.sidePanel.classList.remove('sidepanel-visible');
        this.sidePanel.classList.add('sidepanel-hidden');
        
      } else {
        this.sidePanel.classList.remove('sidepanel-hidden');
        this.sidePanel.classList.add('sidepanel-visible');
      }
    },
    _sidePanelClose(){
      this._sidePanelToggler();
    },
    logout: async function() {
      let success = await this.$store.dispatch('auth/logout');
      if(success)
        this.$router.push('login');
    }
  }
};
</script>